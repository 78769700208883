<template>
  <div class="weui-tabbar">
    <div class="weui-tabbar__item" :class="{ 'weui-bar__item_on': currentTab === item.key }" v-for="(item) in tabList"
      :key="item.key" @click="changeTab(item)">
      <div class="label-box">
        <img class="weui-tabbar__icon" :src="currentTab === item.key ? item.iconActive : item.icon">
        <span class="weui-badge weui-badge_dot badge_dot" v-if="typeof dot === 'boolean'"></span>
        <span class="weui-badge badge_num" v-else-if="typeof dot === 'number'">{{ dot }}</span>
      </div>
      <p class="weui-tabbar__label">{{ item.label }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  current: String,
  tabs: Array<TabType>,
  dot: Number as PropType<Boolean | Number>,
});

const emit = defineEmits<{
  (e: 'update:current', tab: string): void
  (e: 'change', tab: TabType): void
}>();

const dot = computed(() => props.dot);

const tabList = computed(() => props.tabs);

const router = useRouter();

const currentTab = computed(() => props.current);

function changeTab(tab: TabType) {
  router.push(tab.url);
  emit('update:current', tab.key);
  emit('change', tab);
}

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="less" scoped>
.label-box {
  position: relative;
  display: inline-block;
}

.badge_num {
  position: absolute;
  top: -2px;
  right: -13px;
}

.badge_dot {
  position: absolute;
  top: 0;
  right: -6px;
}

.weui-tabbar__label {
  font-size: 0.9rem;
}
</style>