import { createApp } from 'vue';
import App from './App.vue';
import store from '@/store';
import router from '@/router';
import 'weui';
import weui, {
  toast,
  loading,
  alert,
  confirm,
  dialog,
  picker,
  datePicker,
  topTips,
} from '@/utils/weui';
import EventBus from '@/utils/eventBus';

import Tabbar from '@cps/tabbar.vue';
import NoData from '@cps/no-data.vue';

const app = createApp(App);

app.component('tabbar', Tabbar);
app.component('no-data', NoData);

app.config.globalProperties.$eventBus = EventBus;
app.config.globalProperties.$weui = weui;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$loading = loading;
app.config.globalProperties.$alert = alert;
app.config.globalProperties.$confirm = confirm;
app.config.globalProperties.$dialog = dialog;
app.config.globalProperties.$picker = picker;
app.config.globalProperties.$datePicker = datePicker;
app.config.globalProperties.$topTips = topTips;

app
  .use(router)
  .use(store)
  .mount('#app');
